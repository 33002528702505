import { combineReducers } from "redux"
import postReducer from "./postReducer"
import relatedReducer from "./relatedReducer"
import imageReducer from "./imageReducer"

const rootReducer = combineReducers({
    post: postReducer,
    related: relatedReducer,
    image: imageReducer
})

export default rootReducer