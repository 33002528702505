const initState = {
    relateds: [],
    errors: null,
    fetching: false,
    fetched: false,
}

const relatedReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_RELATED_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'FETCH_RELATED_SUCCESS':
            return {
                ...state,
                relateds: action.data,
                errors: null,
                fetching: false,
                fetched: true
            }
        case 'FETCH_RELATED_FAILED':
            return {
                ...state,
                errors: action.errors,
                fetching: false,
                fetched: true
            }
        default: 
            return state
        
    }
}

export default relatedReducer