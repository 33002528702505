import React, { Component, Fragment } from 'react'
import Navbar from './Navbar'
import Banner from './Banner'
import ImageFeature from './ImageFeature'

export class Header extends Component {

    render() {
        return (
            <Fragment>
                <div style={styles.header}>
                    {
                        this.props.banner && (
                            <div className="d-none d-md-block" style={styles.backgroundSecond} />
                        )
                    }

                    <Navbar />
                    {
                        this.props.banner && (
                            
                            <Banner />
                        )
                    }

                    {
                        this.props.featureImage && (
                            
                            <ImageFeature image={this.props.image} />
                            
                        )
                    }

                </div>


            </Fragment>
        )
    }
}

const styles = {
    header: {
        backgroundImage: `
            linear-gradient(to bottom, rgba(30, 111, 203, 0.78), rgba(30, 111, 203, 0.95)),
            url('${require('../assets/img/bg.jpg')}')
        `,
        position: 'relative',
        backgroundSize: 'cover'
    },
    backgroundSecond: {
        backgroundImage: `url('${require('../assets/img/bg2.svg')}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        position: 'absolute',
        bottom: '-20%',
        width: '100%',
        height: '55%',
        backgroundSize: 'cover'
    },
}

export default Header
