import React, { Component } from 'react'
import { Switch, Route, Router } from 'react-router-dom'
import Home from './views/Home'
import './assets/css/style.css'
import Post from './views/Post'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

export default class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" component={Home} exact={true} />
          <Route path="/:id" component={Post} exact={true} />
        </Switch>
      </Router>
    )
  }
}
