const initState = {
    image: null,
    errors: null,
    fetching: false,
    fetched: false,
}

const imageReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_IMAGE_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'FETCH_IMAGE_SUCCESS':
            return {
                ...state,
                image: action.data,
                errors: null,
                fetching: false,
                fetched: true
            }
        case 'FETCH_IMAGE_FAILED':
            return {
                ...state,
                errors: action.errors,
                fetching: false,
                fetched: true
            }
        default: 
            return state
        
    }
}

export default imageReducer