import React, { Component } from "react";
import Slider from "react-slick";
import Map from "../components/Map";
import Layout from "../components/Layout";
import MetaTags from "react-meta-tags";

const clients = {
  pdkebersihan: {
    title: "PD. Kebersihan Bandung",
  },
  aisin: {
    title: "PT. Aisin Automotive Indonesia",
  },
  kujang: {
    title: "PT. Pupuk Kujang",
  },
  barry: {
    title: "PT. Papandayan Cocoa Industries",
  },
  dharma: {
    title: "PT. Dharma Group Indonesia",
  },
  dharma_precision: {
    title: "PT. Dharma Precision Tools",
  },
  dharma_precision_tools: {
    title: "PT. Dharma Precision Tools",
  },
  abc: {
    title: "PT. ABC President Indonesia",
  },
  yamaha: {
    title: "PT. Yamaha Manufacturing Indonesia",
  },
  sanoh: {
    title: "PT. Sanoh Indonesia",
  },
  asian_paragames: {
    title: "Asian Paralympic Games 2018",
  },
  sugity: {
    title: "PT. Sugity",
  },
  dishub_jkt: {
    title: "Dinas Perhubungan DKI Jakarta",
  },
  dki: {
    title: "Pemerintahan DKI Jakarta",
  },
  dharma_electrindo: {
    title: "PT. Dharma Electrindo",
  },
  gs_battery: {
    title: "PT. GS Battery",
  },
  mitsubishi: {
    title: "PT. Mitsubishi",
  },
  sekiso: {
    title: "PT. Sekiso Industries Indonesia",
  },
  acs: {
    title: "ACS Group",
  },
  adaptive: {
    title: "Adaptive",
  },
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 10000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default class Home extends Component {
  state = {
    client: "mitsubishi",
    menuOpen: false,
  };

  hoverCustomer = (client) => {
    this.setState({
      ...this.state,
      client,
    });
  };

  render() {
    return (
      <Layout banner={true}>
        <MetaTags>
          <title>Kelolabiz | Software Agency, Consultant and Developer</title>
          <meta
            name="description"
            content="Kelola Keuangan Bisnis &amp; SDM anda dengan lebih mudah secara Realtime Online menggunakan aplikasi http://Kelola.biz Gratis. Coba sekarang. (022) 875 29767"
          />
          <meta
            property="og:title"
            content="Kelolabiz | Software Agency, Consultant and Developer"
          />
        </MetaTags>

        <div
          className="container-fluid mb-5"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="d-flex row justify-content-around mx-4">
            <div
              className="col-md-4 col-sm-12 justify-self-center px-4"
              style={styles.intro}
            >
              <div style={styles.introImgWrapper}>
                <img
                  src={require("../assets/img/t2.svg")}
                  alt="Subscribe Me"
                  style={styles.introImg}
                />
              </div>
              <h6
                className="font-weight-bold text-uppercase"
                style={styles.title}
              >
                Subscribe Me
              </h6>
              <p className="sans">SaaS ERP / MRP Murah Bayar Bulanan</p>
            </div>
            <div
              className="col-md-4 col-sm-12 justify-self-center px-4"
              style={styles.intro}
            >
              <div style={styles.introImgWrapper}>
                <img
                  src={require("../assets/img/t1.svg")}
                  alt="Custom Request"
                  style={styles.introImg}
                />
              </div>
              <h6
                className="font-weight-bold text-uppercase"
                style={styles.title}
              >
                Custom Request Software Solution
              </h6>
              <p className="sans">(Made-To-Order)</p>
            </div>
            <div
              className="col-md-4 col-sm-12 justify-self-center px-4"
              style={styles.intro}
            >
              <div style={styles.introImgWrapper}>
                <img
                  src={require("../assets/img/t3.svg")}
                  alt="Product IOT"
                  style={styles.introImg}
                />
              </div>
              <h6
                className="font-weight-bold text-uppercase"
                style={styles.title}
              >
                IoT Device/Hardware
              </h6>
              <p className="sans">(Smart Sensor)</p>
            </div>
          </div>
        </div>

        <div style={styles.headerTwo}>
          <div className="background-third" />
          <div className="container-fluid" style={styles.bgBlue}>
            <div className="row px-5">
              <div className="col-sm-12 d-flex justify-content-center pb-5">
                <h6 style={styles.title}>
                  we <strong>focus</strong>
                </h6>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/integration.svg")}
                    alt="System Integration"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  System Integration
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/iot.svg")}
                    alt="Iot Sensor System"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Iot Sensor System
                </small>
              </div>
              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/qrbarcode.svg")}
                    alt="Qr/Barcode"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Qr/Barcode
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/cloud.svg")}
                    alt="Cloud Computing"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Cloud Computing
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/autonomus.svg")}
                    alt="Autonomus System"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Robotics and Autonomous Systems
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/simulations.svg")}
                    alt="Simulations"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Simulations &amp; Interactive Dashboard
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/bigdata.svg")}
                    alt="Big data machine learning"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Big data &amp; machine learning
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/productive.svg")}
                    alt="Predictive &amp; Preventive Maintenance"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Predictive &amp; Preventive Maintenance
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/qty.svg")}
                    alt="Quality &amp; Quantity Control"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Quantity &amp; Quality Control System Monitoring [QA QC]
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/monitoring.svg")}
                    alt="Delivery Monitoring GPS Tracking"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Fleet / FG Delivery Monitoring Tracking (GPS)
                </small>
              </div>
              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/inventory.svg")}
                    alt="Inventory &amp; Warehouse"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  Inventory &amp; Warehousing Management System
                </small>
              </div>

              <div
                className="col-md-3 px-auto pb-5 col-6 justify-self-center"
                style={styles.feature}
              >
                <div style={styles.featureWrapper} className="m-auto">
                  <img
                    src={require("../assets/img/hris.svg")}
                    alt="HRIS"
                    style={styles.introImg}
                    className="mb-2"
                  />
                </div>
                <small
                  className="text-dark font-weight-bold"
                  style={styles.subtitle}
                >
                  HRIS [Human Resources Information System]
                </small>
              </div>
            </div>
          </div>

          <div style={styles.headerThree} className="pb-5">
            <div className="background-fourth" />
            <div className="container" style={{ paddingTop: "180px" }}>
              <div className="d-flex justify-content-center mt-5 mb-4">
                <h4
                  style={styles.title}
                  className="text-center text-uppercase text-white font-weight-bold text-yellow"
                >
                  Mengapa menggunakan kelola.biz
                </h4>
              </div>

              <div className="row mt-5 d-flex justify-content-center">
                <div className="d-flex px-5 col-md-4">
                  <div className="text-center justify-content-center">
                    <h1
                      className="text-yellow mb-4"
                      style={{ fontSize: "4em" }}
                    >
                      <i className="icon-customer-service"></i>
                    </h1>
                    <h5
                      className="text-white font-weight-bold"
                      style={styles.headerCaption}
                    >
                      DAPAT DIANDALKAN
                    </h5>
                    <p style={styles.caption} className="mb-5 text-white">
                      Kami sudah bekerjasama dengan banyak perusahaan nasional
                      di Indonesia untuk membantu kesuksesan perusahaan dalam
                      menjalankan proses bisnis mereka.
                    </p>
                  </div>
                </div>

                <div className="d-flex px-5 col-md-4">
                  <div className="text-center justify-content-center">
                    <h1
                      className="text-yellow mb-4"
                      style={{ fontSize: "4em" }}
                    >
                      <i className="icon-consultant"></i>
                    </h1>
                    <h5
                      className="text-white font-weight-bold"
                      style={styles.headerCaption}
                    >
                      REKAN PROFESIONAL
                    </h5>
                    <p style={styles.caption} className="mb-5 text-white">
                      Tim kami adalah para ahli IT professional yang
                      berpengalaman mendampingi berbagai perusahaan.{" "}
                    </p>
                  </div>
                </div>

                <div className="d-flex px-5 col-md-4">
                  <div className="text-center justify-content-center">
                    <h1
                      className="text-yellow mb-4"
                      style={{ fontSize: "4em" }}
                    >
                      <i className="icon-leader"></i>
                    </h1>
                    <h5
                      className="text-white font-weight-bold"
                      style={styles.headerCaption}
                    >
                      INOVASI TIADA HENTI
                    </h5>
                    <p style={styles.caption} className="mb-5 text-white">
                      Kami senantiasa melakukan inovasi tiada henti untuk
                      menyesuaikan dengan perkembangan zaman dan menyediakan
                      solusi mutakhir bagi permasalahan perusahaan.
                    </p>
                  </div>
                </div>

                <div className="d-flex px-5 col-md-4">
                  <div className="text-center justify-content-center">
                    <h1
                      className="text-yellow mb-4"
                      style={{ fontSize: "4em" }}
                    >
                      <i className="icon-collaboration"></i>
                    </h1>
                    <h5
                      className="text-white font-weight-bold"
                      style={styles.headerCaption}
                    >
                      AKOMODASI IDE ANDA
                    </h5>
                    <p style={styles.caption} className="mb-5 text-white">
                      Ide perusahaan Anda atau proses khusus di perusahaan dapat
                      kami akomodasi agar system yang diterapkan bisa bekerja
                      dengan efektif.
                    </p>
                  </div>
                </div>

                <div className="d-flex px-5 col-md-4">
                  <div className="text-center justify-content-center">
                    <h1
                      className="text-yellow mb-4"
                      style={{ fontSize: "4em" }}
                    >
                      <i className="icon-ecology"></i>
                    </h1>
                    <h5
                      className="text-white font-weight-bold"
                      style={styles.headerCaption}
                    >
                      MENDUKUNG INDUSTRI 4.0
                    </h5>
                    <p style={styles.caption} className="mb-5 text-white">
                      Komitmen kami adalah menjadi rekan untuk kesuksesan
                      perusahaan dalam menghadapi era revolusi industri 4.0
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            style={{ backgroundColor: "#F4F2F2" }}
          >
            <div className="container pb-5 pt-5">
              <div className="d-flex justify-content-center mt-5">
                <h6
                  style={styles.title}
                  className="text-uppercase font-weight-bold"
                >
                  who work with
                </h6>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <p style={styles.title2} className="text-muted">
                  We help Companies & Manufactures to be ready for The Fourth
                  Industrial Revolution (IR 4.0). Delivered more than 150+
                  Projects, work with 35+ Big Company (mostly Multinational
                  Japanese Company)!
                </p>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <p style={styles.title2} className="text-muted">
                  Have Better Production Management With Us!
                </p>
              </div>
              <div className="d-flex justify-content-center mt-5">
                <h6 className="font-weight-bold text-dark text-center client-text">
                  {clients[this.state.client] &&
                    clients[this.state.client].title}
                </h6>
              </div>
            </div>
          </div>

          <div
            className="container-fluid mt-5 mb-5"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="d-flex justify-content-center">
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("mitsubishi")}
                  className={
                    this.state.client !== "mitsubishi"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/mitsubishi.png")}
                  alt="PT Mitsubishi Motors Kramayudha Indonesia"
                />
              </div>
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("abc")}
                  className={
                    this.state.client !== "abc" ? `img-gray` : "img-cursor"
                  }
                  src={require("../assets/img/abc.png")}
                  alt="PT ABC President Indonesia"
                />
              </div>
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("sanoh")}
                  className={
                    this.state.client !== "sanoh" ? `img-gray` : "img-cursor"
                  }
                  src={require("../assets/img/sanoh.png")}
                  alt="Sanoh"
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("dharma")}
                  className={
                    this.state.client !== "dharma" ? `img-gray` : "img-cursor"
                  }
                  src={require("../assets/img/dharma.png")}
                  alt="Dharma Group"
                />
              </div>
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() =>
                    this.hoverCustomer("dharma_precision_tools")
                  }
                  className={
                    this.state.client !== "dharma_precision_tools"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/dharma-precision-tools.png")}
                  alt="Dharma Group"
                />
              </div>
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("dharma_electrindo")}
                  className={
                    this.state.client !== "dharma_electrindo"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/dharma_electrindo.png")}
                  alt="Dharma Electrindo"
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("dki")}
                  className={
                    this.state.client !== "dki" ? `img-gray` : "img-cursor"
                  }
                  src={require("../assets/img/dki.png")}
                  alt="Pemerintahan DKI Jakarta"
                />
              </div>
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("asian_paragames")}
                  className={
                    this.state.client !== "asian_paragames"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/asian_paragames.png")}
                  alt="Asian Paralympic Games"
                />
              </div>
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("dishub_jkt")}
                  className={
                    this.state.client !== "dishub_jkt"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/dishub_jkt.png")}
                  alt="Dinas Perhubungan DKI Jakarta"
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("sugity")}
                  className={
                    this.state.client !== "sugity" ? `img-gray` : "img-cursor"
                  }
                  src={require("../assets/img/sugity.png")}
                  alt="PT Sugity"
                />
              </div>

              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("gs_battery")}
                  className={
                    this.state.client !== "gs_battery"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/gs-battery.png")}
                  alt="PT GS Battery"
                />
              </div>
              
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("sekiso")}
                  className={
                    this.state.client !== "sekiso"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/sekiso.jpeg")}
                  alt="PT Sekiso Industries Indonesia"
                />
              </div>     
            </div>
            <div className="d-flex justify-content-center">
              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("acs")}
                  className={
                    this.state.client !== "acs" ? `img-gray` : "img-cursor"
                  }
                  src={require("../assets/img/acs.jpeg")}
                  alt="ACS Group"
                />
              </div>

              <div className="p-2">
                <img
                  width="200"
                  onMouseEnter={() => this.hoverCustomer("adaptive")}
                  className={
                    this.state.client !== "adaptive"
                      ? `img-gray`
                      : "img-cursor"
                  }
                  src={require("../assets/img/adaptive.jpeg")}
                  alt="Adaptive"
                />
              </div>    
            </div>
            <div className="d-flex justify-content-center mt-4">
              <p style={styles.title2} className="text-muted">
                And Many More
              </p>
            </div>
            {/* <Slider {...settings2}>
                                <div className="px-5" style={{ width: '100px' }}>
                                    <img onMouseEnter={() => this.hoverCustomer('abc')} className={this.state.client !== 'abc' ? `img-gray` : 'img-cursor' } src={require('../assets/img/abc.png')} alt="ABC" style={styles.introImg} />
                                </div>
                            </Slider> */}
          </div>

          <div
            className="container-fluid mt-5 p-5"
            style={{ backgroundColor: "#0A67ED" }}
          >
            <div className="row d-flex justify-content-center">
              {/* <div className="d-flex justify-content-center"> */}
              <div className="col-md-2 col-sm-12 justify-content-center">
                <img
                  src={require("../assets/img/contact.svg")}
                  alt="Contact Us"
                  className="img-contact"
                />
              </div>
              <div className="col-md-6 col-sm-12 justify-content-center">
                <h2 className="text-white text-center" style={styles.title}>
                  <strong>Request a</strong> call back.
                </h2>
                <form>
                  <div className="row mt-4">
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <div className="select-kelolabiz">
                          <select className="select-form-kelolabiz">
                            <option value="software">Software</option>
                            <option value="hardware">Hardware</option>
                            <option value="consultation">Consultation</option>
                            <option value="outsource">Outsource</option>
                            <option value="agency">Agency</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-kelolabiz"
                          placeholder="Phone number"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-kelolabiz"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="form-group">
                        <button className="btn btn-yelow">
                          Submit
                          <i className="mdi mdi-arrow-right ml-5"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                {/* </div> */}
              </div>
            </div>
          </div>

          <Map />
        </div>
      </Layout>
    );
  }
}

const styles = {
  intro: {
    zIndex: 2,
    textAlign: "center",
  },
  introImgWrapper: {
    width: "200px",
    height: "300px",
    display: "inline-block",
  },
  introImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  title: {
    fontFamily: "'Montserrat', sans-serif",
    color: "#00317E",
    zIndex: 2,
  },
  title2: {
    fontFamily: "'Montserrat', sans-serif",
    color: "#00317E",
    zIndex: 2,
    fontSize: "1.5em",
    textAlign: "center",
  },
  headerTwo: {
    backgroundColor: "#fff",
    position: "relative",
  },
  bgBlue: {
    backgroundColor: "#F3F8FF",
    marginTop: "100px",
  },
  feature: {
    zIndex: 2,
    textAlign: "center",
    width: "100px",
  },
  featureWrapper: {
    width: "80px",
    height: "80px",
  },
  subtitle: {
    fontFamily: "'Montserrat', sans-serif",
    color: "#00317E",
    zIndex: 2,
    display: "flex",
    textAlign: "center",
    marginTop: "10px",
    justifyContent: "center",
  },
  headerThree: {
    backgroundImage: `
            linear-gradient(
                rgba(30, 111, 203, 0.78), 
                rgba(30, 111, 203, 0.95)), 
                url(${require("../assets/img/bg2.jpg")})`,
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  headerCaption: {
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  caption: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: "1em",
  },
};
