import React, { Component, Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

export class Layout extends Component {
  render() {
    return (
      <Fragment>
        <Header
          featureImage={this.props.featureImage}
          image={this.props.image}
          banner={this.props.banner}
        />
        <Fragment>{this.props.children}</Fragment>
        <Footer />
        <a
          href="https://api.whatsapp.com/send?phone=6285171058488&amp;text=Halo"
          className="float"
          target="_blank"
        >
          <i class="mdi mdi-whatsapp m-auto"></i>
        </a>
      </Fragment>
    );
  }
}

export default Layout;
