import Axios from "axios"
import { url } from "../../global"

const fetchPost = (slug) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'FETCH_POST_PENDING'
        })

        Axios.get(`${url}/wp-json/wp/v2/posts?slug=${slug}`)
            .then(res => {
                dispatch({
                    type: 'FETCH_POST_SUCCESS',
                    data: res.data
                })
            }).catch(error => {

                dispatch({
                    type: 'FETCH_POST_FAILED',
                    errors: error.response
                })
        })
    } 

}

const fetchRelated = (category) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'FETCH_RELATED_PENDING'
        })

        Axios.get(`${url}/wp-json/wp/v2/post/related?category_id=${category}`)
            .then(res => {
                dispatch({
                    type: 'FETCH_RELATED_SUCCESS',
                    data: res.data
                })
            }).catch(error => {

                dispatch({
                    type: 'FETCH_RELATED_FAILED',
                    errors: error.response
                })
        })
    }  
}

const fetchImage = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'FETCH_IMAGE_PENDING'
        })

        Axios.get(`${url}/wp-json/wp/v2/media/${id}`)
            .then(res => {
                dispatch({
                    type: 'FETCH_IMAGE_SUCCESS',
                    data: res.data
                })
            }).catch(error => {

                dispatch({
                    type: 'FETCH_IMAGE_FAILED',
                    errors: error.response
                })
        })
    }  
}

export { fetchPost, fetchRelated, fetchImage }